import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import TaskIcon from '@mui/icons-material/Task';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import axios from '../../../../api/axios';
const URL = "./dealership";

function ViewDealership() {
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const navigate = useNavigate();

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const columns = [
        { field: 'slNo', headerName: 'ID', width: 200 },
        { field: "accountName", headerName: "Account Name", width: 200 },
        { field: "accountPhone", headerName: "Account Phone", width: 200 },
        { field: "license_number", headerName: "License Number", width: 200 },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 250,
            cellClassName: 'actions',
            getActions: (params) => {
                return [
                    // <EditData selectedRow={params.row}/>,
                    // <DeleteData selectedRow={params.row} />,                        
                    // <Block selectedRow={params.row} />
                    <EditData selectedRow={params.row} />,
                    // <DeleteData selectedRow={params.row} />
                ];
            }
        },
    ];



    const [dataList, setDataList] = useState(''); // Initialize with dummy data

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const response = await axios.get(URL);

            if (response.data.status === 401) {
                setDataList(""); // Keep dummy data in case of unauthorized response
            } else {
                const responseData = response.data.data;
                const dataWithIndex = response.data.data.map((item, index) => ({
                    ...item,
                    slNo: index + 1, // Assign sequential SL No starting from 1
                })) || "";
                setDataList(dataWithIndex);
            }
        } catch (err) {
            console.log("Error fetching data:", err);
            // Use dummy data if request fails
            setDataList('');
        }
    };

    const EditData = (props) => {
        return (
            <EditIcon style={{ cursor: "pointer" }} onClick={(e) => {
                e.stopPropagation();
                navigate('/AddDealership', { state: { type: "update", value: props.selectedRow } });

            }} />
        );
    }

    const DeleteData = (props) => {
        return (
            <DeleteIcon
                onClick={() => {
                    console.log(props.selectedRow.id);
                    const data = { id: props.selectedRow.id };
                    const mainURL = URL + '/' + data.id + '/delete';
                    // serviceMethod(mainURL,data, handleSuccess, handleException);
                }}
            />
        );
    };


    const handleSubmit = (e) => {
        navigate('/AddDealership', { state: { type: "add", value: "" } });
        // const method = "POST";
        // const data = { oldPassword: currentPassword, newPassword, email: user_email };
        // const mainURL = URL;
        // serviceMethod(mainURL, method, data, handleSuccess, handleException);
    }

    return (
        <div style={{ marginTop: "100px", padding: "0px" }}>
            <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
              
                <Box sx={{ flexGrow: 1, padding: '10px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} />
                        <Grid item xs={2}>
                        <Button color="inherit"
                                    onClick={(e) => handleSubmit(e)}
                                    sx={{
                                        backgroundColor: '#0d2365',
                                        color: 'white',  // Change background color to purple
                                        '&:hover': {
                                            backgroundColor: '#0d2365',  // Darken the color on hover
                                        },
                                        borderRadius: '10px'
                                    }}

                                    startIcon={<AddIcon fontSize="var(--icon-fontSize-md)" />}>
                                    Add Dealership
                                </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Stack spacing={3}>
                    <Stack direction="row" spacing={3}>
                      
                       

                        <div>
                            {/* <Button startIcon={<AddIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={toggleDrawer("right", true)}>
                                Add
                            </Button> */}
                        </div>
                    </Stack>
                </Stack>
     
                {/* <Customer  toggleDrawer={toggleDrawer} state={state}  /> */}
                <DataGrid
                    rows={dataList}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    components={{
                        Toolbar: () => (
                            <Box
                                sx={{
                                    padding: 2,
                                    display: 'flex',
                                    justifyContent: 'flex-start', // Aligns to the left
                                    borderBottom: '0.2px solid grey',  // Adds bottom border
                                }}
                            >
                                <Typography variant="h6"
                                        sx={{
                                            fontWeight: 'medium',
                                            // fontSize: '16px', // Use fontSize instead of font
                                            color: 'darknavy',
                                            fontFamily: 'Montserrat, sans-serif' // Ensure proper font family syntax
                                        }}>
                                    Dealership
                                </Typography>
                            </Box>
                        ),
                    }}
                    sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                            color: 'darknavy', // Set column header text color to dark navy blue
                            fontWeight: 'bold', // Make column header text bold
                        },
                    }}
                />
            </Box>

        </div>
    );
}

export default ViewDealership;
