import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@mui/material/Typography';
import UserModelComponent from "./UserModelComponent";
import axios from "../../../api/axios";
import { useAuthContext } from "../../../context/AuthContext";
const URL = './user/getUser';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const UserList = () => {
    const columns = [
        { field: 'slNo', headerName: 'ID', width: 200 },
        {
            field: 'user_name',
            headerName: 'User name',
            width: 200,
            editable: false,
        },
        {
            field: 'user_email',
            headerName: 'Email',
            width: 200,
            editable: false,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 200,
            cellClassName: 'actions',
            getActions: (params) => {
                return [
                    <DeleteData selectedRow={params.row} />
                ];
            }
        },
    ];

    const [open, setOpen] = useState(false);
    const [isAddButton, setIsAddButton] = useState(true);
    const [editData, setEditData] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [isLoading, setGridLoading] = useState(true);
    const [refreshData, setRefreshData] = useState(false);
    const [trackno, setTrackno] = useState('');

    const serviceMethod = async (mainURL, data, handleSuccess, handleException) => {
        try {
            const response = await axios.delete(mainURL, data);
            return handleSuccess(response.data);
        } catch (err) {
            if (!err?.response) {
                console.log("No server response");
            } else {
                return handleException(err?.response.data);
            }
        }
    };

    const serviceUpdateMethod = async (mainURL, data, handleSuccess, handleException) => {
        try {
            const response = await axios.post(mainURL, data);
            console.log(response.data.data);
            
        } catch (err) {
            if (!err?.response) {
                console.log("No server response");
            } else {
                return handleException(err?.response.data);
            }
        }
    };

    useEffect(() => {
        loadData();
    }, [refreshData]);

    const loadData = async () => {
        try {
            const response = await axios.get(URL);
            const dataWithIndex = response.data.data.map((item, index) => ({
                ...item,
                slNo: index + 1, // Assign sequential SL No starting from 1
            })) || "";
            setDataList(dataWithIndex || '');
            // setDataList(response.data.data || '');
        } catch (err) {
            if (!err?.response) {
                console.log("No server response");
            } else {
                console.log(err?.response.data);
            }
        }
    };

    const EditData = (props) => {
        return (
            <EditIcon style={{ cursor: "pointer" }} onClick={(e) => {
                e.stopPropagation();
                console.log(props.selectedRow.id);
                setEditData(props.selectedRow);
                setIsAddButton(false);
                setOpen(true);
            }} />
        );
    };

    const DeleteData = (props) => {
        return (
            <DeleteIcon style={{ cursor: "pointer" }}
                onClick={() => {
                    console.log(props.selectedRow.id);
                    const data = { id: props.selectedRow.id };
                    const mainURL = './user' + '/' + data.id + '/deleteUser';
                    serviceMethod(mainURL, data, handleSuccess, handleException);
                }}
            />
        );
    };

    const Block = (props) => {
        return (
            <>
                {
                    props.selectedRow.block == '0' ?
                        <LockOpenIcon
                            onClick={() => {
                                console.log(props.selectedRow.block);
                                var oldBlock = props.selectedRow.block;
                                var block = oldBlock == 1 ? 0 : 1;
                                const data = { id: props.selectedRow.id, block: block };
                                const mainURL = URL + '/' + data.id + '/updateBlockStatus';
                                serviceUpdateMethod(mainURL, data, handleSuccess, handleException);
                            }}
                        /> :
                        <LockIcon
                            onClick={() => {
                                console.log(props.selectedRow.block);
                                var oldBlock = props.selectedRow.block;
                                var block = oldBlock == 1 ? 0 : 1;
                                const data = { id: props.selectedRow.id, block: block };
                                const mainURL = URL + '/' + data.id + '/updateBlockStatus';
                                serviceUpdateMethod(mainURL, data, handleSuccess, handleException);
                            }}
                        />
                }
            </>
        );
    };

    const handleSuccess = (data) => {
        setOpen(false);
        setRefreshData((oldValue) => {
            return !oldValue;
        });
    };

    const handleException = (data) => {
        console.log(data);
    };

    return (
        <div style={{ marginTop: '75px', padding: '25px' }}>
            <div className="topContent">
                <Box sx={{ flexGrow: 1, padding: '10px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} />
                        <Grid item xs={2}>
                            <Button variant="contained"
                                sx={{
                                    backgroundColor: '#0d2365',  // Change background color to navy
                                    '&:hover': {
                                        backgroundColor: '#0d2365',  // Darken the color on hover
                                    },
                                    borderRadius: '10px'
                                }}
                                onClick={(e) => {
                                    setIsAddButton(true);
                                    setOpen(true);
                                    setEditData([]);
                                }}
                            >
                                Add User
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="GridContent">
                <Box sx={{ flexGrow: 1, padding: '0px', height: 400, width: '100%' }}>
                    <DataGrid
                        rows={dataList}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        experimentalFeatures={{ newEditingApi: false }}
                        getRowId={(row) => row.id}
                        components={{
                            Toolbar: () => (
                                <Box
                                    sx={{
                                        padding: 2,
                                        display: 'flex',
                                        justifyContent: 'flex-start', // Aligns to the left
                                        borderBottom: '0.2px solid grey', // Adds bottom border
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'medium',
                                            // fontSize: '16px', // Use fontSize instead of font
                                            color: 'darknavy',
                                            fontFamily: 'Montserrat, sans-serif' // Ensure proper font family syntax
                                        }}
                                    >
                                        Users
                                    </Typography>
                                </Box>
                            ),
                        }}
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                color: 'darknavy', // Set column header text color to dark navy blue
                                fontWeight: 'bold', // Make column header text bold
                            },
                        }}
                    />
                    <UserModelComponent
                        isAddButton={isAddButton}
                        setOpen={setOpen}
                        open={open}
                        rowData={editData}
                        setRefreshData={setRefreshData}
                        trackno={trackno}
                    />
                </Box>
            </div>
        </div>
    );
};

export default UserList;
