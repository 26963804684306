import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, DialogTitle, FormControl, TextField, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from "../../../api/axios";
const URL = './user/createUser';

const UserModelComponent = ({ open, setOpen, isAddButton, rowData, setRefreshData, trackno }) => {
    //basic information
    const [id, setId] = useState('');
    const [user_email, setUser_email] = useState('');
    const [user_name, setUser_name] = useState('');
    const [user_password, setUser_password] = useState('');
    const [alertOpen, setAlertopen] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    const handleClick = () => {
        setAlertopen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertopen(false);
    };

    const serviceMethod = async (mainURL, method, data, handleSuccess, handleException) => {
        try {
            const response = await axios.post(mainURL, data);
            return handleSuccess(response.data);
        } catch (err) {
            if (!err?.response) {
                console.log("No server response");
            } else {
                return handleException(err?.response.data);
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const method = "POST";
        if (isAddButton) {
            const data = { user_email, user_name, user_password };
            const mainURL = URL;
            serviceMethod(mainURL, method, data, handleSuccess, handleException);
        } else {
            const data = { user_email, user_name, user_password };
            const mainURL = URL + '/' + data.id + '/update';
            serviceMethod(mainURL, method, data, handleSuccess, handleException);
        }
    };

    useEffect(() => {
        setOpen(open);
        if (isAddButton) {
            setUser_email("");
            setUser_name("");
        } else {
            loadData(rowData);
        }
    }, [rowData, isAddButton]);

    const loadData = () => {
        console.log(rowData);
        setId(rowData.id);
        setUser_email(rowData.user_email);
        setUser_name(rowData.user_name);
    };

    const handleSuccess = (data) => {
        setSeverity("success");
        setMessage("Data Added Successfully");
        setAlertopen(true);
        setTimeout(() => {
            setOpen(false);
            setRefreshData((oldValue) => !oldValue);
        }, 6000); // Matches autoHideDuration
    };

    const handleException = (data) => {
        setSeverity("error");
        setMessage(data.data);
        setAlertopen(true);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: '100%' } }}
            open={open}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    sx={{
                        color: 'navy', // Text color (affects the outline for outlined buttons)
                        borderColor: 'navy', // Outline color
                        '&:hover': {
                            borderColor: 'darkblue', // Darken the outline on hover
                        },
                    }}>
                    {isAddButton ? "Add User" : "Edit User"}
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Username
                                    </Typography>
                                    <TextField
                                        value={user_name}
                                        margin="dense"
                                        id="outlined-basic"
                                        variant="outlined"
                                        required
                                        onChange={(e) => { setUser_name(e.target.value) }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'lightgray', // Default border color
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'lightgray', // Remove hover border color
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'lightgray', // Remove focus border color
                                                },
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Email
                                    </Typography>
                                    <TextField
                                        value={user_email}
                                        margin="dense"
                                        id="outlined-basic"
                                        variant="outlined"
                                        required
                                        onChange={(e) => { setUser_email(e.target.value) }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Typography variant="subtitle2" gutterBottom>
                                        User Password
                                    </Typography>
                                    <TextField
                                        value={user_password}
                                        margin="dense"
                                        id="outlined-basic"
                                        variant="outlined"
                                        required
                                        onChange={(e) => { setUser_password(e.target.value) }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ margin: '10px' }}>
                    <Button
                        size="large"
                        variant="outlined"
                        sx={{
                            color: 'navy',
                            borderColor: 'navy',
                            '&:hover': {
                                borderColor: 'darkblue',
                            },
                        }}
                        autoFocus
                        onClick={(e) => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                       
                        sx={{
                            backgroundColor: '#0d2365',  // Change background color to navy blue
                            '&:hover': {
                                backgroundColor: '#0d2365',  // Darken the color on hover
                            },
                            borderRadius:'10px'
                        }}
                        type="submit"
                    >
                        {isAddButton ? "Add" : "Update"}
                    </Button>
                </DialogActions>
                <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </form>
        </Dialog>
    );
}

export default UserModelComponent;
