import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PricingSubTabAppearnce from './PricingSubTabAppearance';
import PricingSubTabGapFinancial from './PricingSubTabGapFinancial';
import PricingPremiumGCC from './PricingPremiumGCC';
import PricingTrimCares from './PricingTrimCares';
import PricingEssantialGCC from './PricingEssentialGCC';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function PricingTab({ packages, setPackages, packagesTypes, setPackagesType, productIndex, setProductIndex, setProductName, setProductCost, handleNext, setPackagesText }) {
  const theme = useTheme();
  const [value, setValue] = React.useState(packages);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPackages(newValue);
    setPackagesText(packagesTypesLabel[newValue]);
  };

  const packagesTypesLabel = [
    "Premium Gcc",
    "Appearance Packages",
    "Essential GCC",
    "Trim Care Tires",
    "Gap Financial Protection bundle"
  ];

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}> {/* Full-width */}
      <AppBar position="static" sx={{ width: '100%',bgcolor:'#626c91',color:'white' }}> {/* Full-width AppBar */}
        <Tabs
          value={value}
          onChange={handleChange}
          // indicatorColor="secondary"
          textColor="white"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {/* Customized Tab styling for dark blue background when selected */}
          {packagesTypesLabel.map((label, index) => (
            <Tab label={label} {...a11yProps(index)} key={index} sx={{
              bgcolor: value === index ? '#b21b1f' : '#313031',  // Dark blue when active
              color: value === index ? 'white' : 'white'        // White text when active
            }} />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <PricingPremiumGCC packagesTypes={packagesTypes} setPackagesType={setPackagesType} productIndex={productIndex} setProductIndex={setProductIndex} setProductName={setProductName} setProductCost={setProductCost} handleNext={handleNext} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <PricingSubTabAppearnce packagesTypes={packagesTypes} setPackagesType={setPackagesType} productIndex={productIndex} setProductIndex={setProductIndex} setProductName={setProductName} setProductCost={setProductCost} handleNext={handleNext} />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
      <PricingEssantialGCC packagesTypes={packagesTypes} setPackagesType={setPackagesType} productIndex={productIndex} setProductIndex={setProductIndex} setProductName={setProductName} setProductCost={setProductCost} handleNext={handleNext} />
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <PricingTrimCares packagesTypes={packagesTypes} setPackagesType={setPackagesType} productIndex={productIndex} setProductIndex={setProductIndex} setProductName={setProductName} setProductCost={setProductCost} handleNext={handleNext} />
      </TabPanel>
      <TabPanel value={value} index={4} dir={theme.direction}>
        <PricingSubTabGapFinancial packagesTypes={packagesTypes} setPackagesType={setPackagesType} productIndex={productIndex} setProductIndex={setProductIndex} setProductName={setProductName} setProductCost={setProductCost} handleNext={handleNext} />
      </TabPanel>
    </Box>
  );
}
